



























import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import {privateLogin} from '@/modules/common/api'
import {addSign} from '@/utils/user'

@Component({
  components: {
  }
})
export default class LoginPage extends Vue {
  username: string = ''
  password: string = ''
  loading: boolean = false
  success: boolean = false
  btnDisabled: boolean = true

  login() {
    let {username, password} = this
    let successUrl = (this.$route as any).query.successUrl || window.location.origin
    if (this.loading) {
      return
    }
    if (!username) {
      this.$message('请输入账号')
      return
    }
    if (!password) {
      this.$message('请输入密码')
      return
    }
    this.loading = true
    privateLogin({
      username,
      password
    }).then((e: any) => {
      if (e.error) {
        this.$message(e.message)
      } else {
        this.success = true
        this.btnDisabled = true
        window.location.href = addSign(successUrl, e)
      }
      this.loading = false
    }, (e: any) => {
      this.loading = false
      this.$message('服务器繁忙')
    })
  }

  check() {
    if (!this.username) {
      this.btnDisabled = true
    } else if (!this.password) {
      this.btnDisabled = true
    } else {
      this.btnDisabled = false
    }
  }

  get privateInfo() {
    return this.$store.state.privateInfo
  }

  get logo() {
    if (this.privateInfo.login_image && this.privateInfo.login_image.host) {
      return this.privateInfo.login_image.host + this.privateInfo.login_image.file
    } else {
      return require('@/assets/common/privite-default-logo.png')
    }
  }
}
